// just ours
$xs: 0;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$header-height: 350px;
$primary2: #24A0C3;
$secondary-hover: #e10000;
$mud: #5a5f58;
$sand: #CDC8C3;
$off-white: #fafafa;
$twitter: #1da1f2;
$facebook: #3c5a9a;
$qdf-1: #24A0C3;
$qdf-2: #1F4F54;
$gray-550: #8D959D; // https://meyerweb.com/eric/tools/color-blend/#ADB5BD:6C757D:1:hex

// bs overrides
$grid-breakpoints: (
        xs: $xs,
        sm: $sm,
        md: $md,
        lg: $lg,
        xl: $xl
);
$border-radius: 7px;
$primary: #1F4F54;
$secondary: #F34D4A;
$success: #24a251;
$success-light: #b0eab8;
$info: #17a2b8;
$warning: #ffc107;
$danger: #c02e29;
$danger-light: #eeb3b2;
$white: #fff;
$black: #000;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$link-color: $primary2;
$link-hover-color: #00cefc;
$border-color: #dee2e6;
$navbar-light-color: $gray-600;
$navbar-light-hover-color: $gray-800;
$dropdown-link-hover-bg: $gray-200;
$body-color: $mud;
$link-hover-decoration: none;
$font-size-base: 1.1rem;
$line-height-base: 1.8;








