$font-path: '/wp-content/themes/tcc/fonts/quicksand';
$font-family: 'Quicksand', sans-serif;
@font-face {
  font-family: 'Quicksand';
  src: url($font-path + '/Quicksand-VariableFont_wght.ttf') format("woff2 supports variations"),
  url($font-path + '/Quicksand-VariableFont_wght.ttf') format("woff2-variations");
  font-weight: 100 1000;
  $style: "normal";
  font-display: block;
}

// remove after we get rid of bootstrap
body {
  font-family: $font-family;
  font-weight: 500;
  text-rendering: optimizeLegibility;
}

@mixin font($wght:500,$important:'') {
  @if $important == '' {
    font-family: $font-family;
    font-weight: $wght;
    text-rendering: optimizeLegibility;
    font-display: block;
  } @else {
    font-family: $font-family !important;
    font-weight: $wght !important;
    text-rendering: optimizeLegibility !important;
    font-display: block !important;
  }
}

